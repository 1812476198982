<template>
  <v-card>
    <v-card-title class="font-weight-bold"> {{ $t('trainings.changePublishDate') }} </v-card-title>

    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" sm="12" justify="center">
          <div class="wrap-tabs">
            <v-tabs v-model="tabs">
              <v-tab class="primary--text" :key="'date'" style="width: 50%">
                <v-icon>mdi-calendar</v-icon>
              </v-tab>
              <v-tab class="primary--text" :key="'time'" style="width: 50%">
                <v-icon>mdi-clock-time-four</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item :key="'date'">
                <v-row justify="center">
                  <v-date-picker
                    width="302px"
                    style="border-radius: unset; height: 404px"
                    v-model="datePicker"
                  ></v-date-picker>
                </v-row>
              </v-tab-item>
              <v-tab-item :key="'time'">
                <v-row justify="center">
                  <v-time-picker
                    style="border-radius: unset"
                    width="302px"
                    format="24hr"
                    v-model="timePicker"
                  ></v-time-picker>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <div class="d-flex justify-end">
              <p class="px-2 clickable" @click="$emit('cancel')">
                {{ $t(`admin.training.trainingList.popup.button.annuler`) }}
              </p>
              <p
                @click="onClickOk"
                v-if="datePicker && timePicker"
                class="green--text px-5 clickable"
              >
                {{ $t('globals.ok') }}
              </p>
              <p v-else class="px-5 un-clickable">{{ $t('globals.ok') }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ChangePublishDate',
  components: {},
  props: ['curItem'],
  data: () => ({
    tabs: null,
    datePicker: null,
    timePicker: null,
  }),
  computed: {},
  methods: {
    onClickOk() {
      this.$emit('submit', new Date(`${this.datePicker} ${this.timePicker}`));
      this.$emit('cancel');
    },
  },
  mounted() {
    this.datePicker = `${new Date(this.curItem.published).toISOString().slice(0, 10)}`;
    this.timePicker = `${new Date(this.curItem.published).getHours()}:${new Date(
      this.curItem.published,
    ).getMinutes()}`;
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();
.wrap-tabs {
  box-shadow: 0 0 8px var(--v-gray-base);
}

.clickable {
  cursor: pointer;
}

.un-clickable {
  cursor: not-allowed;
}
</style>
