<template>
  <v-card>
    <v-card-title class="font-weight-bold">
      {{ $t(`admin.training.trainingList.table.headers.publish`) }}
    </v-card-title>

    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" justify="center" sm="12">
          <div>
            <v-btn
              class="justify-center d-flex px-4 mx-auto"
              color="primary"
              width="150px"
              @click="onClickPublicNow"
            >
              Publish Now
            </v-btn>
            <p class="text-center my-4">or</p>
          </div>
          <div class="wrap-tabs">
            <v-tabs v-model="tabs">
              <v-tab :key="'date'" class="primary--text" style="width: 50%">
                <v-icon>mdi-calendar</v-icon>
              </v-tab>
              <v-tab :key="'time'" class="primary--text" style="width: 50%">
                <v-icon>mdi-clock-time-four</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item :key="'date'">
                <v-row justify="center">
                  <v-date-picker
                    v-model="datePicker"
                    style="border-radius: unset; height: 404px"
                    width="302px"
                  ></v-date-picker>
                </v-row>
              </v-tab-item>
              <v-tab-item :key="'time'">
                <v-row justify="center">
                  <v-time-picker
                    v-model="timePicker"
                    format="24hr"
                    style="border-radius: unset"
                    width="302px"
                  ></v-time-picker>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <div class="d-flex justify-end">
              <p class="px-2 clickable" @click="$emit('cancel')">
                {{ $t(`admin.training.trainingList.popup.button.annuler`) }}
              </p>
              <p
                v-if="datePicker && timePicker"
                class="green--text px-5 clickable"
                @click="onClickOk"
              >
                Ok
              </p>
              <p v-else class="px-5 un-clickable">Ok</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PublicFormationItem',
  components: {},
  props: {},
  data: () => ({
    tabs: null,
    datePicker: null,
    timePicker: null,
  }),
  computed: {},
  methods: {
    onClickPublicNow() {
      this.$emit('submit', new Date());
    },
    onClickOk() {
      this.$emit('submit', new Date(`${this.datePicker} ${this.timePicker}`));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();
.wrap-tabs {
  box-shadow: 0 0 8px var(--v-gray-base);
}

.clickable {
  cursor: pointer;
}

.un-clickable {
  cursor: not-allowed;
}
</style>
